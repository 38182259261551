<!--  -->
<template>
  <div
    class="
      home
      page-template
      page-template-template-home
      page-template-template-home-php
      page
      page-id-11
      wpb-js-composer
      js-comp-ver-6.4.2
      vc_responsive
    "
  >
    <Headers></Headers>
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="main" class="site-main" role="main">
          <div class="content_container directors">
            <!-- 头部banner图 -->
            <div class="section_banner_container">
              <div class="section_banner">
                <div class="container">
                  <div class="row">
                    <div class="col-xs-12 col-sm-11">
                      <div class="section_banner_content">
                        <h1 class="section_banner_heading">董事会</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 新闻列表 -->
            <div class="container">
              <div class="dirHeader">
                <div class="title"><strong>董事会</strong></div>
                <div class="span"></div>
              </div>
              <div
                class="dirMain"
                v-for="(item, index) in usersList"
                :key="index"
              >
                <div class="userHeader">
                  <div class="userImg">
                    <img :src="$imgurl + item.cover" alt="" />
                  </div>
                  <div class="userName">
                    <span
                      ><strong class="name">{{ item.name }}</strong></span
                    >
                    <span>{{ item.position }}</span>
                  </div>
                </div>
                <div class="userInfo" v-html="item.content"></div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
    <!-- #content -->
    <Footers></Footers>
  </div>
</template>

<script>
import Headers from "../../components/header/header.vue";
import Footers from "../../components/footer/footer.vue";
import { queryUsers } from "../../util/api.js";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { Headers, Footers },
  metaInfo() {
    return {
      title: "盈喜集团董事会 - 盈喜集团股份有限公司|ATXG",
      meta: [
        {
          name: "keywords",
          content: "盈喜集团董事会，盈喜董事局，盈喜管理层，ATXG",
        },
        {
          name: "description",
          content:
            "盈喜集团具备完善和稳定的董事会机构，以洪志达为董事局主席，领导董事会为盈喜集团的发展方向和管理运营提供支持。",
        },
      ],
    };
  },
  data() {
    //这里存放数据
    return {
      // 董事会成员
      usersList: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 初始化新闻列表
    async init() {
      const { data: res } = await this.$http.get(queryUsers);
      console.log(res);
      this.usersList = res.result.records;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.init();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
.directors {
  text-align: left;
  .section_banner {
    background-image: url(../../../public/home/img/bg-section-banner-contact.jpg);
  }
  .container {
    .dirHeader {
      // text-align: center;
      display: flex;
      flex-direction: column;
      // align-items: center;
      border-bottom: 1px solid #444;
      position: relative;
      padding-bottom: 20px;
      .title {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 32px;
        color: #3082c0;
      }
      .span {
        z-index: 1;
        width: 199px;
        height: 10px;
        background-color: rgb(4, 107, 186);
        position: absolute;
        top: 50px;
      }
    }
    .dirMain {
      .userHeader {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-top: 1px solid rgb(48, 130, 192);
        padding: 20px 0;
        .userImg {
          width: 331px;
          height: auto;
          img {
            width: 60%;
            height: auto;
          }
        }
        .userName {
          display: flex;
          flex-direction: column;
          margin-left: 20px;
          span:nth-child(2) {
            margin-top: 10px;
          }
          span {
            font-size: 22px;
            font-family: Arial, Helvetica, sans-serif;
            .name {
              font-size: 32px;
              color: rgb(67, 67, 67);
            }
          }
        }
      }
    }
    .userInfo {
      font-family: Calibri;
      font-size: 18px;
      border-top: 1px solid rgb(48, 130, 192);
      padding: 20px 0;
    }
    p {
      margin: 0;
    }
    .left {
      p {
        font-size: 48px;
      }
    }
    .right {
      p {
        font-size: 20px;
      }
    }
  }
}
</style>